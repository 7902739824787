<template>
  <div class="modal text-gray-800 border-t-4 border-blue-500">
    <img :src="src">
  </div>
</template>

<script>
export default {
  props: ['src']
}
</script>
